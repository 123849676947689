import {getPowerBiReportPages} from '@cox2m/city-services-ui-components/src/funcs';

export const staticMenuOptions = user => [
  {
    key: 'home',
    path: '/home',
    name: 'Home',
    icon: 'dashboard-2',
    shouldShow: true
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];

export const buildMenuWithDynamicItems = async (user, TOKEN) => {
  let tempMenuOptions = staticMenuOptions(user);

  try {
    const response = await getPowerBiReportPages({
      workspaceId: '2a9d04e1-3229-4343-87ce-8519e3d93747',
      reportId: 'fab18b23-e7f1-472e-b69b-ada557bbcfe4',
      token: TOKEN
    });

    let homeEntry = tempMenuOptions.find(
      menuOpt => menuOpt.key === 'home'
    );

    homeEntry.subItems = response.value.map(page => ({
      key: `home-page-${page.order}`,
      path: `/home/${encodeURI(page.displayName).replace('/', '-')}`,
      name: page.displayName,
      icon: 'clipboard-text',
      shouldShow: true
    }));
  } catch (e) {
    console.error(e);
  }

  return tempMenuOptions;
};
