import MediaAnalytics from './forms/MediaAnalytics.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/home/:page?': MediaAnalytics,

  '*': NotFoundPage
};

export const openRoutes = []

export default routes
